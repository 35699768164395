// 26357 Story - Allow Customer to Close the Eyebrow Bar on the Storefront
// 26357 Story - Renamed file to eyebrowBar.js and changed the code accordingly
'use strict';

var eyebrowBar = function () {
    var headerBannerStatus = window.sessionStorage.getItem('hide_header_banner');
    var browserMessageStatus = window.sessionStorage.getItem('hide_browser_message_banner');
    $('#promo-slot').on('click', function () {
        $(this).parent('.header-top-banner').remove();
        window.sessionStorage.setItem('hide_header_banner', '1');
    });
    $('#browser-message').on('click', function () {
        $(this).parent('.header-top-banner').remove();
        window.sessionStorage.setItem('hide_browser_message_banner', '1');
    });
    if (headerBannerStatus && headerBannerStatus > 0) {
        $('#promo-slot').parent('.header-top-banner').remove();
    } else {
        $('#promo-slot').parent('.header-top-banner').removeClass('d-none');
    }
    if (browserMessageStatus && browserMessageStatus > 0) {
        $('#browser-message').parent('.header-top-banner').remove();
    } else {
        $('#browser-message').parent('.header-top-banner').removeClass('d-none');
    }
    // 60324
    if ($('.header-top-banner').length !== 0) {
        $('#learnMoreModal').detach().appendTo('body');
    }
    // 60324
};
module.exports = eyebrowBar;
