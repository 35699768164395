'use strict';

function enforcePatternValidationInclusion(ths) {
    var zipRegexPattern = '(^\\d{5}(-\\d{4})?$)|(^[abceghjklmnprstvxyABCEGHJKLMNPRSTVXY]{1}\\d{1}[A-Za-z]{1} *\\d{1}[A-Za-z]{1}\\d{1}$)'; // '(^\d{5}(-\d{4})?$)|(^[abceghjklmnprstvxyABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Za-z]{1} *\d{1}[A-Za-z]{1}\d{1}$';
    var countryFieldRef = $('select[name=dwfrm_registration_country]');
    var accountPostalcodeFieldRef = $('input[name=dwfrm_registration_postalCode]');
    var billingPostalcodeFieldRef = $('input[name=dwfrm_billing_addressFields_postalCode]');

    var countryWhiteList = ['US', 'CA']; // To be moved to site preference if any additional changes countries will be added to whitelist
    var countryValue = "";

    if (countryFieldRef.length > 0) {
        countryValue = "" + countryFieldRef.val();
    }

    if (accountPostalcodeFieldRef.length > 0) {
        if (countryWhiteList.indexOf(countryValue.toUpperCase()) > -1) {
            accountPostalcodeFieldRef.attr('pattern', zipRegexPattern);
        } else {
            accountPostalcodeFieldRef.removeAttr('pattern');
        }
    }
    if (billingPostalcodeFieldRef.length > 0) {
        countryFieldRef = $('select[name=dwfrm_billing_addressFields_country]');
        if (countryFieldRef.length > 0) {
            countryValue = "" + countryFieldRef.val();
        }
        if (countryWhiteList.indexOf(countryValue.toUpperCase()) > -1) {
            billingPostalcodeFieldRef.attr('pattern', zipRegexPattern);
        } else {
            billingPostalcodeFieldRef.removeAttr('pattern');
        }
    }
}

/**
 * Validate whole form. Requires `this` to be set to form object
 * @param {jQuery.event} event - Event to be canceled if form is invalid.
 * @returns {boolean} - Flag to indicate if form is valid
 */
function validateForm(event) {
    var valid = true;
    enforcePatternValidationInclusion(this);

    var validityResult = (this.checkValidity && !this.checkValidity());
    if (validityResult) {
        // safari
        valid = false;
        if (event) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        }
        $(this).find('input, select').each(function () {
            if (!this.validity.valid) {
                $(this).trigger('invalid', this.validity);
            }
        });
    }
    return valid;
}

/**
 * Remove all validation. Should be called every time before revalidating form
 * @param {element} form - Form to be cleared
 * @returns {void}
 */
function clearForm(form) {
    $(form).find('.form-control.is-invalid').removeClass('is-invalid');
}

module.exports = {
    invalid: function () {
        $('form input, form select').on('invalid', function (e) {
            e.preventDefault();
            this.setCustomValidity('');
            if (!this.validity.valid) {
                var validationMessage = this.validationMessage;
                $(this).addClass('is-invalid');
                if (this.validity.patternMismatch && $(this).data('pattern-mismatch')) {
                    validationMessage = $(this).data('pattern-mismatch');
                }
                if ((this.validity.rangeOverflow || this.validity.rangeUnderflow)
                    && $(this).data('range-error')) {
                    validationMessage = $(this).data('range-error');
                }
                if ((this.validity.tooLong || this.validity.tooShort)
                    && $(this).data('range-error')) {
                    validationMessage = $(this).data('range-error');
                }
                if (this.validity.valueMissing && $(this).data('missing-error')) {
                    validationMessage = $(this).data('missing-error');
                }
                // 119989
                if (this.validity.patternMismatch && $(this).data('pattern-mismatch') && (this.validity.tooLong || this.validity.tooShort) && $(this).data('range-error')) {
                    validationMessage = $(this).data('pattern-mismatch');
                }
                $(this).parents('.form-group').find('.invalid-feedback')
                    .text(validationMessage);
            }
        });
    },

    submit: function () {
        $('form').on('submit', function (e) {
            return validateForm.call(this, e);
        });
    },

    buttonClick: function () {
        $('form button[type="submit"], form input[type="submit"]').on('click', function () {
            // clear all errors when trying to submit the form
            clearForm($(this).parents('form'));
        });
    },
    /*
     * Story 29419 Unauthorized Access To Order
     * Displays an help text message when the input email box is focused or customer tries to change the email ID
     */
    onEmailChange: function () {
        $('#dwfrm_registration input[name="dwfrm_registration_email"]').focus(function () {
            var message = $(this).parents('.form-group').attr('data-emailchangemsg');
            $(this).addClass('is-invalid');
            $(this).parents('.form-group').find('.invalid-feedback').html(message);
        });
    },

    onBlur: function () {
        $('form input, form select').on('blur', function (e) {
            // clear this specific error, then validate
            clearForm($(this).closest('.form-group'));
            return validateForm.call(this, e);
        });
        /*
         * Story 29419 Unauthorized Access To Order.
         * When customer enters email in the PI page during checkout, it is checked if a customer exists with the email given.
         * If customer exists, it will display a error message.
         * If customer doesn't exist, the flow will be continued.
         */
        $('#dwfrm_registration input[name="dwfrm_registration_email"]').blur(function () {
            if ($(this).val() !== '') {
                var url = $(this).parents('.form-group').attr('data-url');
                var currentEmail = $(this).val();
                $.spinner().start();
                $.ajax({
                    url: url,
                    type: 'GET',
                    data: { currentEmail: currentEmail },
                    success: function (data) {
                        if (data.error === true) {
                            $('input#email').addClass('is-invalid');
                            $('input[name="dwfrm_registration_email"]').parents('.form-group').find('.invalid-feedback').html(data.message);
                        }
                        $.spinner().stop();
                    },
                    error: function () {
                        $.spinner().stop();
                    }
                });
            }
        });
    },

    functions: {
        validateForm: function (form, event) {
            validateForm.call($(form), event || null);
        },
        clearForm: clearForm
    }
};
