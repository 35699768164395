'use strict';
// 69358
// 69359
// 168198
function loadMqlModel() {
    $(window).on('load', function () {
        setTimeout(function () {
            $('#mqlModal').modal('show');
        }, 300);
    });
}
// 45260
function closeMqlModel() {
    var url = $("#mqlClose").data('url');
    $.ajax({
        url: url,
        type: 'get',
        dataType: 'json',
        success: function () {
            $("#mqlModal").modal('hide');
        }
    });
}
// 38477 - mql integration work - show error message when api call fails
function showError($modal, message) {
    var errorContainer = $modal.find('.form-error');
    var errorMessage = errorContainer.find('.form-error__message');
    errorContainer.show();
    errorMessage.text(message);
}
// 38477 - mql integration work - hide error message
function hideError($modal, message) {
    var errorContainer = $modal.find('.form-error');
    var errorMessage = errorContainer.find('.form-error__message');
    errorContainer.hide();
    errorMessage.text('');
}
// 48846 - SFCC sends commCode cookie value whenever calling Marketo MQL
function trackCodeForMql() {
    var url = $("#emailButton").data('url');
    var tracking = '';
    $.ajax({
        async: false,
        url: url,
        type: 'GET',
        success: function (response) {
            tracking = response.trackingCode;
        },
        failure: function () {
            // eslint-disable-next-line
            console.log('fetching tracking code failed');
        }
    });
    return tracking;
}
module.exports = {
    formSubmit: function () {
        // $('#mqlModal').modal('show');
        loadMqlModel();

        $('#mqlModal').on('hidden.bs.modal', function (e) {
            closeMqlModel();
        });
        $("#mqlClose").on('click', function () {
            closeMqlModel();
        });
        var mailformat = /^[\w.%+-]+@[\w.-]+\.[\w]{2,6}$/;
        // 38477 - mql integration work -Start
        var $modal = $('.mql-modal-data');
        hideError($modal);
        $modal.on('click', '#emailButton', function () {
            var jnEmail = $("#emailCapture").val();
            if ($.trim(jnEmail).length === 0) {
                $("#emailCaptureFeedback").text("Please enter your email address.");
                $("#emailCapture").addClass('is-invalid');
            } else if ($.trim(jnEmail).length !== 0 && mailformat.test(jnEmail)) {
                $("#emailCapture").removeClass('is-invalid');
                var valid = true;
                var errorMessage = 'Error encountered when submitting the form. Please try again.';
                var url = $modal.data('mql-api-endpoint');
                var token = $modal.data('mql-api-token');
                var rawTrackingCode = trackCodeForMql(); // 48846 - To check commcode value and if available assign as rawTrackingCode
                var country = $modal.data('mql-country');
                var institution = $modal.data('mql-institution-code');
                var rawPrimaryProgramOfInterest = $modal.data('mql-program-of-interest');
                var data = {
                    email: (jnEmail !== null) ? jnEmail : '',
                    raw_tracking_comm_code: (rawTrackingCode !== null) ? rawTrackingCode : '',
                    country: (country !== null) ? country : '',
                    institution: (institution !== null) ? institution : '',
                    raw_primary_program_of_interest: (rawPrimaryProgramOfInterest !== null) ? rawPrimaryProgramOfInterest : ''
                };
                if (typeof url === 'undefined' || typeof token === 'undefined') {
                    valid = false;
                }
                if (valid) {
                    $modal.find('.mqlPopUp').spinner().start();
                    $.ajax({
                        url: url,
                        type: 'post',
                        headers: {
                            'API-TOKEN': token,
                            'Content-Type': 'application/json'
                        },
                        dataType: 'json',
                        data: JSON.stringify(data),
                        success: function success(obj) {
                            $modal.find('.mqlPopUp').spinner().stop();
                            if (obj.status === "200") {
                                $("#thankyouModal").removeClass("d-none").addClass("d-block");
                                $("#signUpModal").removeClass("d-block").addClass("d-none");
                            } else {
                                showError($modal, errorMessage);
                            }
                        },
                        error: function error(err) {
                            $modal.find('.mqlPopUp').spinner().stop();
                            showError($modal, errorMessage);
                        }
                    });
                } else {
                    showError($modal, errorMessage);
                }
                // 38477 - mql integration work - End
            } else {
                $("#emailCaptureFeedback").text("Please enter a valid email address.");
                $("#emailCapture").addClass('is-invalid');
            }
        });
    }
};
// 45260
