'use strict';

var base = require('base/components/search');

base = typeof base === 'function' ? { baseInit: base } : { ...base };

base.initMobileHeaderSearch = function () {
    $('.show-mobile-search').on('click', function (e) {
        e.stopImmediatePropagation();
        $(this).toggleClass('active');
        if ($(this).hasClass('active')) {
            $(this).find('.mobile-menu').hide();
        } else {
            $(this).find('.mobile-menu').show();
        }
        $('.search-mobile').toggleClass('d-none');
    });
};

module.exports = base;
